<template lang="pug">
  div(v-if="navigatorLanguage === 'es'")
    div.d-flex.fill-height(style="width: 100%; column-gap: 20px;")
      div.d-flex.flex-column.justify-start
        div(style="font-size: 14px;")
          p(style="font-size: 16px; color: #F93549; font-weight: 600; margin-bottom: 2em;") CÓMO SE CALCULA
          span(style="font-weight: 600;") Paso 1
          p(style="margin-bottom: 4em;") Se calcula el consumo estándar del restaurante, para ello dividimos el total de ítems vendidos de cada Familia en un periodo de tiempo (1 mes por ejemplo), por el total de clientes atendidos en el mismo periodo.
          p Además podemos calcular también el #[strong NRC (Número de Referencias por Cliente)], que es el mejor indicador para gestionar la venta sugestiva del restaurante. Se calcula dividiendo el total de productos consumidos por el total de clientes en el periodo.

      div.d-flex.flex-column.justify-center(style="width: 100%")
        div.pt-15
          card_2_-s-v-g_1


  div(v-else-if="navigatorLanguage === 'en'")
    div.d-flex.fill-height(style="width: 100%; column-gap: 20px;")
      div.d-flex.flex-column.justify-start
        div(style="font-size: 14px;")
          p(style="font-size: 16px; color: #F93549; font-weight: 600; margin-bottom: 2em;") HOW IT IS CALCULATED
          span(style="font-weight: 600;") Step 1
          p(style="margin-bottom: 4em;") First of all, we calculate the standard consumption of the restaurant. For this, we divide the total items sold for each Family in a period (1 month, for example) by the total number of customers served in the same period.
          p In addition, we can also calculate the #[strong NRC (Number of References per Customer)],  which is the best indicator to manage the suggestive sale of the restaurant.  It is calculated by dividing the total of products consumed by the sum of clients in the period.

      div.d-flex.flex-column.justify-center(style="width: 100%")
        div.pt-15
          card_2_-s-v-g_1_en
</template>

<script>
import Card_2_SVG_1 from "./assets/Card_2_SVG_1";
import Card_2_SVG_1_en from "@/components/guidedHelp/IPS/assets/Card_2_SVG_1_en";

export default {
  name: "Card_2",
  components: {Card_2_SVG_1_en, Card_2_SVG_1}
}
</script>

<style scoped>

</style>
